<template>
    <div class="row col-12 row-md col-md-8 offset-md-4 tufont tutablepadding text-start tusmallerfontsize" style="outline:0.00em dashed red; height: auto; float: left; padding-top: 1vh">
        <span class="col-1 offset-1 col-md-1 offset-md-2 tufont;" style="outline:0.00em dashed green;"><img style="width: clamp(20px, 45%, 25px); cursor:none" alt="yes" src="../assets/tick1smblue.webp"></span>
        <span class="col-9 col-md-9" style="outline:0.00em dashed blue;" v-html="this.ListItemString"></span>
    </div>
</template>

<script>
export default {
    name: 'HomePageTickedListItem',
    props:  {
        ListItemString: String,
    },
} 
</script> 
   
           
           
